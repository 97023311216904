import { Button } from 'antd';
import { debounce, filter, get, isNil, isNumber, pickBy } from 'lodash';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
  PlanningConsumer,
  PlanningContext,
} from '../../../context/PlanningContext';
import Table, { SELECTION_TYPE, TableOptions } from '../../Table/Table';
import {
  extensionRowRender,
  isItemDisabled,
  isItemDisabledInfo,
  selectionCellRenderer,
} from './PlanningStepLocationsCommon';
import createLocationTableHeader, {
  TabledHeaderField,
} from './createLocationTableHeader';

import { notification } from 'antd';
import { DateTime } from 'luxon';
import { LngLatBounds, LngLatLike } from 'mapbox-gl';
import qs from 'query-string';
import { withRouter } from 'react-router';
import styled from 'styled-components';
import request from 'superagent';
import { Request } from '../../../api/Request';

import { datadogLogs } from '@datadog/browser-logs';
import { ReactComponent as PrintIcon } from '../../../assets/icons/printer.svg';
import { ReactComponent as RefreshIcon } from '../../../assets/icons/refresh.svg';
import { ReactComponent as HomeIcon } from '../../../assets/icons/home.svg';
import { AuthConsumer } from '../../../context/AuthContext';
import { RouteInfo } from '../../../context/Route';
import getTasks from '../../Location/getTasks';
import { parseDate } from '../../Picker/DatePicker';
import { Comparator } from '../../Table/Filter';
import DateFilter from '../../Tours/DateFilter';
import CountryFilter from './PlanningStepLocationsFilter/CountryFilter';
import ForesightedMonthCount from './PlanningStepLocationsFilter/ForesightedMonthCount';
import MapBoundsFilter from './PlanningStepLocationsFilter/MapBoundsFilter';
import RemainingOrderValueFilter from './PlanningStepLocationsFilter/RemainingOrderValue';
import PlanningStepLocationsHeatmap from './PlanningStepLocationsHeatmap';
import DueWithin48Months from './PlanningStepLocationsFilter/DueWithin48Months';

const Legend = styled.ul`
  list-style: none;
  padding: 0;
  margin: 4px 0;
  width: 100%;
`;

const LegendItem = styled.li<{
  color: string;
}>`
  margin: 0 12px 8px 0;
  float: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${(props) => props.color};
  svg {
    width: 12x;
    height: 12px;
    margin-right: 8px;
    fill: ${(props) => props.color};
  }
`;

const RefreshIconSpin = styled(RefreshIcon)<{
  spin?: boolean;
}>`
  animation: ${(props) => (props.spin ? 'spin 2s linear infinite' : 'none')};
  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
`;

export enum RemainingOrderValue {
  Ignore = 'ignore',
  Only = 'only',
  PayAttention = 'payattention',
}

export interface PlanningStepLocationsOptions extends TableOptions {
  remainingOrderValue: RemainingOrderValue;
}

export const defaultOptions: PlanningStepLocationsOptions = {
  sort: 'address.postalCode',
  desc: false,
  remainingOrderValue: RemainingOrderValue.PayAttention,
  filters: {
    duePrice: [
      {
        value: '0',
        comparator: Comparator['>N'],
      },
    ],
  },
};

interface MatchParams {
  id: string;
}

interface PlanningStepLocationsProps extends RouteComponentProps<MatchParams> {}

interface PlanningStepLocationsState {
  error?: Error;
  loading: boolean;
  options: PlanningStepLocationsOptions;
  majorOptions: { [key: string]: string[] };
  foresightedMonthCount: number;
  items: any[];
  items48: any[];
  techs: any[];
  // unfilteredValues: { [col: string]: string[] };
  header: TabledHeaderField[];
  bounds: LngLatBounds | undefined;
  useBounds: boolean;
}

function inBounds(point: LngLatLike, bounds: LngLatBounds) {
  return bounds.contains(point);
}

const MapBar = styled.div`
  height: 32px;
  padding: 4px 0;
  display: flex;
`;

export const getRemainingOrderValueFromString = (value: string) => {
  switch (value) {
    case 'ignore':
      return RemainingOrderValue.Ignore;
    case 'only':
      return RemainingOrderValue.Only;
    case 'payattention':
      return RemainingOrderValue.PayAttention;
    default:
      return RemainingOrderValue.PayAttention;
  }
};

export const getStringFromRemainingOrderValue = (
  value: RemainingOrderValue
) => {
  switch (value) {
    case RemainingOrderValue.Ignore:
      return 'ignore';
    case RemainingOrderValue.Only:
      return 'only';
    case RemainingOrderValue.PayAttention:
      return 'payattention';
    default:
      return 'payattention';
  }
};

export const getTitleFromRemainingOrderValue = (value: RemainingOrderValue) => {
  switch (value) {
    case RemainingOrderValue.Ignore:
      return 'Auftragswert ignorieren';
    case RemainingOrderValue.Only:
      return 'Auftragswert ist null';
    case RemainingOrderValue.PayAttention:
      return 'Auftragswert anwenden';
    default:
      return 'Auftragswert';
  }
};

class PlanningStepLocations extends Component<
  PlanningStepLocationsProps,
  PlanningStepLocationsState
> {
  storeOptionsAndLoadDebounced: (planningWeek: Date) => void;

  oldRequest?: request.SuperAgentRequest;

  state: PlanningStepLocationsState = {
    loading: true,
    options: defaultOptions,
    foresightedMonthCount: 0,
    items: [],
    items48: [],
    techs: [],
    // unfilteredValues: {},
    header: [],
    majorOptions: {},
    bounds: undefined,
    useBounds: false,
  };

  constructor(props: PlanningStepLocationsProps) {
    super(props);
    this.handleOptionsChanged = this.handleOptionsChanged.bind(this);
    this.storeOptionsAndLoadDebounced = debounce(this.storeOptionsAndLoad, 500);
  }

  setPromisifiedState(data: any) {
    return new Promise<void>((resolve) => this.setState(data, () => resolve()));
  }

  async componentDidMount() {
    await this.context.setWasEdit(false);
    await this.loadOptionsFromURL();
    this.loadWithPlaningWeek();
  }

  loadOptionsFromURL() {
    const data = qs.parse(this.props.location.search);
    return new Promise<void>((resolve) => {
      const s: PlanningStepLocationsOptions = {
        filters:
          data && data.filters
            ? JSON.parse(data.filters as string)
            : defaultOptions.filters,
        sort: data && data.sort ? (data.sort as string) : defaultOptions.sort,
        desc: data && data.desc ? data.desc === 'true' : defaultOptions.desc,
        remainingOrderValue: getRemainingOrderValueFromString(
          data.remainingOrderValue as string
        ),
      };
      this.setState(
        {
          options: s,
          foresightedMonthCount: Number(
            data.foresighted || this.state.foresightedMonthCount || 0
          ),
        },
        async () => {
          const d = DateTime.fromISO(data.planningWeek as string);
          if (d.isValid) {
            await this.context.setPlanningWeek(d.toJSDate());
          }
          resolve();
        }
      );
    });
  }

  storeOptionsAtURL(planningWeek: Date) {
    // const endDate = DateTime.fromJSDate(planningWeek)
    //   .plus({ months: this.state.foresightedMonthCount })
    //   .toISO();
    const planningWeekString = DateTime.fromJSDate(planningWeek).toISO();

    const s = {
      filters: JSON.stringify(this.state.options.filters),
      sort: this.state.options.sort,
      desc: this.state.options.desc,
      planningWeek: planningWeekString,
      foresighted: this.state.foresightedMonthCount,
      remainingOrderValue: getStringFromRemainingOrderValue(
        this.state.options.remainingOrderValue
      ),
      // end: endDate,
    };

    const stringified = qs.stringify(s);
    window.history.replaceState(s, 'safePlan', `?${stringified}`);
  }

  loadWithPlaningWeek() {
    const { planningWeek } = this.context;
    this.loadData(planningWeek);
  }

  getLoadDateParams(planningWeek: Date, forceForesightedMonthCount?: number) {
    const { foresightedMonthCount } = this.state;

    const endDate = DateTime.fromJSDate(planningWeek)
      .plus({ months: forceForesightedMonthCount ?? foresightedMonthCount })
      .toISO();

    const planningWeekStart = DateTime.fromJSDate(planningWeek)
      .startOf('week')
      .toISO();

    const planningWeekEnd = DateTime.fromJSDate(planningWeek)
      .endOf('week')
      .toISO();

    const f = {
      ...this.state.options.filters,
    };

    if (!f.contractValueRest) {
      f.contractValueRest = [
        {
          value: '0',
          comparator: Comparator['>N'],
        },
      ];
    }

    return {
      filters: JSON.stringify(f),
      sort: this.state.options.sort,
      desc: this.state.options.desc,
      remainingOrderValue: this.state.options.remainingOrderValue,
      end: endDate,
      uncomplete: true,
      hstart: planningWeekStart,
      hend: planningWeekEnd,
    };
  }

  async loadData(planningWeek: Date) {
    this.setPromisifiedState({ loading: true, items48: [] });

    try {
      const headerTasks = await getTasks();
      const header = createLocationTableHeader(
        headerTasks.map(({ key, title }: TabledHeaderField) => ({ title, key }))
      );

      const s = this.getLoadDateParams(planningWeek);
      const s48 = this.getLoadDateParams(planningWeek, 48);

      datadogLogs.logger.log('filter_changed', {
        filters: s,
        timestamp: new Date().toISOString(),
      });

      const [techs, result] = await Promise.all([
        Request.list('planning/technicians/available', {
          limit: 1000,
          page: 0,
          start: s.hstart,
          end: s.hend,
          status: true,
        }),
        Request.list('planning/locations/due', s),
      ]);

      this.setPromisifiedState({
        loading: false,
        items: result.items,
        techs: techs.items,
        header,
      });

      const result48 = await Request.list('planning/locations/due', s48);

      this.setPromisifiedState({
        items48: result48.items,
      });
    } catch (error: any) {
      this.setPromisifiedState({ loading: false, error });
    }
  }

  getPrintUrl(planningWeek: Date) {
    const stringified = qs.stringify(this.getLoadDateParams(planningWeek));
    return `/print/planing/locations?${stringified}`;
  }

  async handleOptionsChanged(
    opts: Partial<PlanningStepLocationsOptions>,
    planningWeek: Date
  ) {
    await this.setPromisifiedState({ isLoaded: false, options: opts });
    this.storeOptionsAtURL(planningWeek);
    this.loadData(planningWeek);
  }

  async handleMajorOptionsChanged(
    majorOptions: { [key: string]: string[] },
    planningWeek: Date
  ) {
    const opts = {
      ...this.state.options,
      filters: {
        ...this.state.options.filters,
        'address.countryCode': (majorOptions['address.countryCode'] || []).map(
          (value) => ({
            comparator: '=',
            value,
          })
        ),
      },
    };
    await this.setPromisifiedState({
      isLoaded: false,
      options: opts,
      majorOptions,
    });
    this.storeOptionsAtURL(planningWeek);
    this.loadData(planningWeek);
  }

  handleSelectionChanged(
    locationsToSet: any[],
    selection: boolean,
    locations: any[],
    setLocations?: (locations: any[]) => Promise<void>,
    setLoadingInfo?: (loading: boolean) => void,
    setInfo?: (info: RouteInfo) => void
  ) {
    let value: any[] = [];

    if (selection) {
      value = locations.concat(locationsToSet);
    } else {
      const ids = locationsToSet.map((l) => l._id);
      value = filter(locations, (l) => !ids.includes(l._id));
    }

    this.loadInfoPreview.bind(this)(value, setLoadingInfo, setInfo);
    setLocations && setLocations(value);
  }

  handleRouteEmptyError(error: any) {
    if (
      error &&
      error.response &&
      error.response.text.includes('routes is empty')
    ) {
      notification.error({
        duration: 0,
        message: 'Vorschau nicht möglich',
        description:
          'Die Route enthält einen Anschrift zu der nicht navigiert werden kann.',
      });
      return true;
    }
    return false;
  }

  handleAbortError(error: any) {
    if (error.code === 'ABORTED') {
      return true;
    }
    return false;
  }

  handleOtherErrors(error: any) {
    notification.error({
      duration: 0,
      message: `Vorschau nicht möglich (Code ${error.code})`,
      description: 'Unbekannter Fehler.',
    });
  }

  loadInfoPreview(
    locations: any[],
    setLoadingInfo?: (loading: boolean) => void,
    setInfo?: (info: RouteInfo) => void
  ) {
    try {
      // stop existing request
      if (this.oldRequest) {
        this.oldRequest.abort();
      }

      // set loading
      setLoadingInfo && setLoadingInfo(true);

      locations.forEach((l) => {
        console.log(
          `${l.name} - ${get(l, 'addressRouting.location.coordinates')}`
        );
      });

      // prepare coordinates
      const reqData = locations.map((l) =>
        get(l, 'addressRouting.location.coordinates')
      );

      // build request
      this.oldRequest = Request.postForRoutePreview(reqData);

      // run request
      this.oldRequest
        .then((res) => res.body)
        .then((data) => {
          // set result and loading
          setInfo && setInfo(data);
        })
        .catch((error) => {
          setLoadingInfo && setLoadingInfo(false);
          if (this.handleRouteEmptyError(error)) return;
          if (this.handleAbortError(error)) return;
          this.handleOtherErrors(error);
        });
    } catch (error: any) {
      setLoadingInfo && setLoadingInfo(false);
      if (this.handleRouteEmptyError(error)) return;
      if (this.handleAbortError(error)) return;
      this.handleOtherErrors(error);
    }
  }

  setForesightedMonthCount(floatValue: number) {
    if (
      !isNil(floatValue) &&
      isNumber(floatValue)
      // floatValue !== this.state.foresightedMonthCount
    ) {
      this.setState({ foresightedMonthCount: floatValue }, () => {
        const { planningWeek } = this.context;
        this.storeOptionsAndLoadDebounced(planningWeek);
      });
    }
  }

  storeOptionsAndLoad(planningWeek: Date) {
    this.storeOptionsAtURL(planningWeek);
    this.loadData(planningWeek);
  }

  renderFilter = (
    options: PlanningStepLocationsOptions,
    majorOptions: { [key: string]: string[] },
    foresightedMonthCount: number,
    loading: boolean,
    planningWeek: Date | undefined,
    setPlanningWeek?: (date: Date) => void,
    count48?: number
  ) => {
    return (
      <>
        <DateFilter
          disabled={loading}
          text='Planungswoche'
          range={
            planningWeek
              ? {
                  startDate: planningWeek,
                  endDate: DateTime.fromJSDate(planningWeek)
                    .plus({ days: 7 })
                    .toJSDate(),
                }
              : undefined
          }
          onChange={(value) => {
            if (planningWeek && value) {
              this.storeOptionsAtURL(planningWeek as Date);
              const jsd = parseDate(value.startDate).toJSDate();
              setPlanningWeek && setPlanningWeek(jsd);
              this.loadData(jsd);
            }
          }}
        />
        <CountryFilter
          disabled={loading}
          options={majorOptions}
          onChange={(options: PlanningStepLocationsOptions) => {
            this.handleMajorOptionsChanged(
              {
                ...majorOptions,
                'address.countryCode': options['address.countryCode'],
              },
              planningWeek!
            );
          }}
        />
        <RemainingOrderValueFilter
          disabled={loading}
          options={options}
          onChange={(options: PlanningStepLocationsOptions) => {
            const remainingOrderValue = getRemainingOrderValueFromString(
              options.remainingOrderValue as string
            );
            this.handleOptionsChanged(
              { ...options, remainingOrderValue },
              planningWeek!
            );
          }}
        />
        <MapBoundsFilter
          disabled={loading}
          useBounds={this.state.useBounds}
          onChange={(useBounds) => {
            this.setState({
              useBounds,
            });
          }}
        />
        <ForesightedMonthCount
          disabled={loading}
          value={foresightedMonthCount}
          onChange={(value) => this.setForesightedMonthCount(value)}
        />
        <DueWithin48Months count={count48} />
      </>
    );
  };

  render() {
    const {
      items,
      items48,
      techs,
      options,
      majorOptions,
      loading,
      foresightedMonthCount,
      // unfilteredValues,
    } = this.state;

    return (
      <AuthConsumer>
        {({ user }: { user: { id?: string } }) => (
          <PlanningConsumer>
            {({
              blocked,
              locations,
              setLocations,
              planningWeek,
              setPlanningWeek,
              setInfo,
              setLoadingInfo,
            }) => {
              return (
                <div className='locations locations-planed customer container-inner container-inner-list'>
                  <div className='page-header row justify-content-between'>
                    <div className='col col-12 col-md-10'>
                      {this.renderFilter(
                        options,
                        majorOptions,
                        foresightedMonthCount,
                        loading,
                        planningWeek,
                        setPlanningWeek,
                        items48?.length
                      )}
                    </div>
                    <div className='col col-12 col-md-2 page-header-actions justify-content-md-end pt-md-0'>
                      {!loading && planningWeek && (
                        <div className='col col-1 page-header-actions justify-content-md-end pt-md-0 foresighted-months'>
                          <Button
                            type='link'
                            onClick={() => {
                              window &&
                                window!
                                  .open(
                                    this.getPrintUrl(planningWeek),
                                    '_blank'
                                  )
                                  ?.focus();
                            }}
                          >
                            <PrintIcon />
                            Drucken
                          </Button>
                        </div>
                      )}
                      {
                        <div className='col col-1 page-header-actions justify-content-md-end pt-md-0 foresighted-months'>
                          <Button
                            disabled={loading}
                            onClick={() => this.loadWithPlaningWeek()}
                            type='link'
                          >
                            <RefreshIconSpin spin={loading} />
                            Aktualisieren {loading && '...'}
                          </Button>
                        </div>
                      }
                    </div>
                  </div>
                  <div className='row page-content'>
                    <div className='col col-9'>
                      <Table
                        loading={loading}
                        filterable={true}
                        selectable={SELECTION_TYPE.Filter}
                        options={options}
                        header={this.state.header}
                        items={items.filter((item) => {
                          if (!this.state.bounds || !this.state.useBounds)
                            return true;
                          const cords = get(
                            item,
                            'addressRouting.location.coordinates'
                          );
                          return inBounds(cords, this.state.bounds);
                        })}
                        // unfilteredValues={unfilteredValues}
                        selection={locations}
                        selectionCellRenderer={selectionCellRenderer}
                        isItemDisabled={(location) =>
                          isItemDisabled(blocked, user, location)
                        }
                        isItemDisabledInfo={(location) =>
                          isItemDisabledInfo(blocked, user, location)
                        }
                        handleOptionsChanged={(opts) =>
                          this.handleOptionsChanged(opts, planningWeek!)
                        }
                        handleSelectionChanged={(locationToSet, selection) => {
                          this.handleSelectionChanged(
                            locationToSet,
                            selection,
                            locations || [],
                            setLocations,
                            setLoadingInfo,
                            setInfo
                          );
                        }}
                        link='/administration/locations'
                        linkTarget='_blank'
                        extensionGetter={(item) => {
                          const {
                            _id,
                            address,
                            addressRouting,
                            tag,
                            customer,
                            contractValueSum,
                            checkDepth,
                            name,
                            allNextJobDate,
                            note,
                            holidays,
                            contractValueRest,
                          } = item;
                          return item.additionalTasks
                            .filter((t: any) => {
                              if (!t.dueFixed) return true;
                              if (!planningWeek) return false;
                              const dt = DateTime.fromISO(t.due);
                              const pt = DateTime.fromJSDate(planningWeek);
                              return (
                                dt.weekYear === pt.weekYear &&
                                dt.weekNumber === pt.weekNumber
                              );
                            }) // alle Termine in der Plan KW
                            .map((p: any) => {
                              return {
                                ...p,
                                disabled:
                                  allNextJobDate && allNextJobDate.length > 0,
                                additionalTask: p._id,
                                locID: _id,
                                address,
                                addressRouting,
                                tag,
                                customer,
                                contractValueSum,
                                checkDepth,
                                name,
                                comment: p.message,
                                note: note,
                                holidays,
                                contractValueRest,
                                operatingExpense: p.planHours,
                                duePrice: p.estimatedPrice,
                                job: p.job,
                                _dues: pickBy(
                                  p.type,
                                  (value, key) => !key.startsWith('_')
                                ),
                                freezeComment: true,
                                fixedDateFromAdditionalTasks: p.dueFixed
                                  ? p.due
                                  : undefined,
                              };
                            })
                            .sort(
                              (firstEl: any, secondEl: any) =>
                                firstEl &&
                                firstEl.fixedDateFromAdditionalTasks &&
                                secondEl &&
                                secondEl.fixedDateFromAdditionalTasks &&
                                firstEl.fixedDateFromAdditionalTasks.localeCompare(
                                  secondEl.fixedDateFromAdditionalTasks
                                )
                            );
                        }}
                        extensionRow={(
                          item: any,
                          index: number,
                          columns: any[],
                          isItemSelected: (id: string) => boolean,
                          isItemDisabled?: (id: string) => boolean,
                          isItemDisabledInfo?: (item: any) => React.ReactNode,
                          handleSelectionChanged?: (
                            item: any,
                            value: boolean
                          ) => void
                        ) => {
                          return extensionRowRender(
                            item,
                            index,
                            columns,
                            isItemSelected,
                            (id: string) =>
                              (isItemDisabled && isItemDisabled(id)) ||
                              item.disabled,
                            () => <></>,
                            handleSelectionChanged
                          );
                        }}
                      />
                    </div>
                    <div className='col col-3 map'>
                      <PlanningStepLocationsHeatmap
                        disabled={loading}
                        onClick={(e, s) => {
                          const locationToSet = e
                            .map((id) => items.find((i) => i._id === id))
                            .filter((i) => !!i);
                          this.handleSelectionChanged(
                            locationToSet,
                            s,
                            locations || [],
                            setLocations,
                            setLoadingInfo,
                            setInfo
                          );
                        }}
                        technicians={{
                          type: 'FeatureCollection',
                          features: techs?.map((item: any) => {
                            return {
                              type: 'Feature',
                              properties: {
                                id: item._id,
                                title: `${item.name}`,
                                status: item.status,
                              },
                              geometry: item.address.location,
                            };
                          }),
                        }}
                        data={{
                          type: 'FeatureCollection',
                          features: items.map((item: any) => {
                            const isPlaned =
                              item.allNextJobDate &&
                              item.allNextJobDate.length > 0;
                            const isSelected =
                              locations &&
                              locations.some((i) => i._id === item._id);
                            return {
                              type: 'Feature',
                              properties: {
                                id: item._id,
                                address: item.addressRouting,
                                status: isPlaned
                                  ? 'planed'
                                  : isSelected
                                  ? 'selected'
                                  : '',
                                value: Math.round(item.duePrice),
                                title: `${item.name}\n${item.tag}\n${item.duePrice}€`,
                              },
                              geometry: item.addressRouting.location,
                            };
                          }),
                        }}
                        onBoundsChange={(bounds) => {
                          this.setState({ bounds });
                          console.log(bounds);
                        }}
                      />
                      <Legend>
                        <LegendItem color='#EE4444'>
                          <HomeIcon />
                          Verfügbar
                        </LegendItem>
                        <LegendItem color='#9F5AFD'>
                          <HomeIcon />
                          Teilweise verfügbar
                        </LegendItem>
                        <LegendItem color='#ffc34d'>
                          <HomeIcon />
                          Geplant
                        </LegendItem>
                        <LegendItem color='#4B4B4B'>
                          <HomeIcon />
                          Urlaub
                        </LegendItem>
                      </Legend>
                    </div>
                  </div>
                </div>
              );
            }}
          </PlanningConsumer>
        )}
      </AuthConsumer>
    );
  }
}

PlanningStepLocations.contextType = PlanningContext;

export default withRouter(PlanningStepLocations);
