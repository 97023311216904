import 'mapbox-gl/dist/mapbox-gl.css';
import React, { useEffect, useRef, useState } from 'react';

import { GeoJSONSource, LngLatBounds } from 'mapbox-gl';
import MapGL, {
  Layer,
  LayerProps,
  NavigationControl,
  Source,
  ViewState,
} from 'react-map-gl';

type Props = {
  data: GeoJSON.FeatureCollection<GeoJSON.Geometry>;
  technicians: GeoJSON.FeatureCollection<GeoJSON.Geometry>;
  onBoundsChange: (bounds: LngLatBounds) => void;
  onClick: (ids: string[], selected: boolean) => void;
  disabled?: boolean;
};

const defaultViewport: ViewState = {
  latitude: 51.163361,
  longitude: 10.447683,
  zoom: 5,
  bearing: 0,
  pitch: 0,
};

export const clusterLayer: LayerProps = {
  id: 'clusters',
  type: 'circle',
  source: 'locations_cluster',
  filter: ['has', 'sum'],
  paint: {
    // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
    // with three steps to implement three types of circles:
    //   * Blue, 20px circles when point count is less than 100
    //   * Yellow, 30px circles when point count is between 100 and 750
    //   * Pink, 40px circles when point count is greater than or equal to 750
    'circle-color': [
      'case',
      ['boolean', ['get', 'active'], true],
      '#ebb400',
      [
        'step',
        ['get', 'sum'],
        '#005927',
        14000,
        '#008037',
        35000,
        '#009842',
        50000,
        '#00A648',
        75000,
        '#00E663',
      ],
    ],

    'circle-opacity': 0.7,
    'circle-radius': ['step', ['get', 'point_count'], 30, 50, 40, 100, 50],
  },
};

export const clusterCountLayer: LayerProps = {
  id: 'cluster-count',
  type: 'symbol',
  source: 'locations_cluster',
  filter: ['has', 'sum'],
  layout: {
    'text-field': ['concat', ['/', ['get', 'sum'], 1000], 'k'],
    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
    'text-size': 12,
  },
  paint: { 'text-color': '#fff' },
};

const markerLayer: LayerProps = {
  id: 'locations',
  type: 'symbol',
  source: 'locations',
  minzoom: 0,
  layout: {
    'icon-anchor': 'bottom',
    'icon-image': [
      'match',
      ['get', 'status'],
      'planed',
      'pin_blue',
      'selected',
      'pin_yellow',
      'pin_gray',
    ],
    'icon-size': 0.5,
    'text-field': ['get', 'title'],
    'text-anchor': 'top',
    'text-allow-overlap': true,
  },
  paint: {
    'text-color': [
      'match',
      ['get', 'status'],
      'planed',
      '#004298',
      'selected',
      '#ebb400',
      '#000',
    ],
  },
};

const techMarkerLayer: LayerProps = {
  id: 'technicians',
  type: 'symbol',
  source: 'technicians',
  minzoom: 0,
  layout: {
    'icon-anchor': 'bottom',
    // 'icon-image': 'pin_home',
    'icon-image': [
      'match',
      ['get', 'status'],
      'avaible',
      'home_red',
      'temporarily-available',
      'home_lila',
      'holidays',
      'home_gray',
      'planed',
      'home_yellow',
      'home_red',
    ],
    'icon-size': 0.5,
    'text-field': ['get', 'title'],
    'text-anchor': 'top',
    'icon-allow-overlap': true,
    'icon-ignore-placement': true,
    'text-allow-overlap': true,
    'text-ignore-placement': true,
  },
  paint: {
    'text-color': [
      'match',
      ['get', 'status'],
      'avaible',
      '#EE4444',
      'temporarily-available',
      '#9F5AFD',
      'planed',
      '#4B4B4B',
      '#EE4444',
    ],
  },
};

const Heatmap: React.FC<Props> = ({
  data,
  technicians,
  onClick,
  onBoundsChange,
  disabled,
}) => {
  const [viewport, setViewport] = useState<ViewState>(defaultViewport);

  useEffect(() => {
    const map = ref.current ? ref.current!.getMap() : undefined;
    if (map) {
      const bounds = map.getBounds();
      onBoundsChange && onBoundsChange(bounds);
    }
  }, [viewport]);

  const ref = useRef<MapGL>(null);

  return (
    <MapGL
      {...viewport}
      className='map-container'
      ref={ref}
      reuseMaps={true}
      width='100%'
      height='100%'
      disableTokenWarning={true}
      mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
      mapStyle='mapbox://styles/notionug/cle8fcf17000q01q1si7vlah3?optimize=true'
      onViewportChange={(v: any) => setViewport(v)}
      onClick={(e) => {
        const map = ref.current ? ref.current!.getMap() : undefined;

        if (!map) return;

        // Is point
        if (e.features && e.features.length > 0) {
          const feature = e.features[0];
          if (feature.layer.id === 'locations') {
            const id = feature.properties.id;
            const status = feature.properties.status;

            if (status === 'planed') return;
            onClick && onClick([id], status !== 'selected');
            return;
          }
        }

        // is Cluster
        const features = map.queryRenderedFeatures(e.point, {
          layers: ['clusters'],
        });

        if (
          !features ||
          features.length === 0 ||
          features[0].properties === null
        )
          return;

        const clusterId = features[0].properties.cluster_id;
        const point_count = features[0].properties.point_count;
        const clusterSource = map.getSource('locations_cluster');
        const active = features[0].properties.active;

        if (!clusterSource || !(clusterSource as any).getClusterChildren) {
          return;
        }

        // Get all points under a cluster
        (clusterSource as GeoJSONSource).getClusterLeaves(
          clusterId,
          point_count,
          0,
          function (err, aFeatures) {
            const ids: string[] = aFeatures
              .map((f) => {
                if (f.properties?.status !== 'planed') {
                  return f.properties?.id;
                } else {
                  return undefined;
                }
              })
              .filter((f) => f !== undefined);
            onClick && onClick(ids, !active);
          }
        );
      }}
    >
      <Source
        type='geojson'
        id='locations_cluster'
        cluster={true}
        data={data}
        clusterProperties={{
          sum: ['+', ['get', 'value'], ''],
          active: ['any', ['==', ['get', 'status'], 'selected']],
        }}
      >
        <Layer {...markerLayer} />
        <Layer {...clusterLayer} />
        <Layer {...clusterCountLayer} />
      </Source>

      <Source
        type='geojson'
        id='technicians'
        cluster={false}
        data={technicians}
      >
        <Layer {...techMarkerLayer} />
      </Source>

      <div
        className='nav'
        style={{ position: 'absolute', top: 0, right: 0, padding: '10px' }}
      >
        <NavigationControl onViewportChange={(v: any) => setViewport(v)} />
      </div>
    </MapGL>
  );
};

export default Heatmap;
